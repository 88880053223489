<template>
  <div class='verify-signature-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class='verify-signature-title'>Let's verify your signature</div>
            <div class='verify-signature-stepper'>Hello {{ clientName }}!</div>

            <div class='verify-signature-sub-text'>
              Your signature should match with the signature on your PAN Card.
            </div>
            <div class='verify-signature-sign-on-screen'>
                <!-- <a-textarea class='signature-text-area'
                  style="background: #e0e0e0"
                  :autosize="false"
                  v-model:value="signatureValue" placeholder="Sign" :rows="4" /> -->
                   <img
                  :src='verifySignature'
                  alt='Verify Signature'
                  class='verify-signature-uploaded-image-wrapper'
                />

            </div>
            <div class="verify-signature-btn-wrapper">
              <a-button type="primary" @click="onClickContinue"
              :loading="loading">Proceed</a-button>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
// import { useRouter } from 'vue-router';
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import STAGES from '../../../constants/stages';
import services from '../../../services/apis';

export default {
  components: {
  },
  setup() {
    const store = useStore();
    const signatureValue = ref('');
    const loading = ref(false);

    onMounted(() => {
      window.scrollTo(0, 0);
      const currentStage = computed(
        () => store.getters['onboardingStore/getCurrentStage'],
      ).value;
      if (currentStage !== STAGES.STAGE_TYPE.SIGNATURE) {
        services
          .getStages()
          .then((response) => {
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          })
          .catch(() => {
          });
      }
    });
    const clientName = computed(
      () => store.getters['onboardingStore/getClientName'],
    ).value;
    const verifySignature = computed(
      () => store.getters['onboardingStore/getVerifySignature'],
    ).value;
    // const router = useRouter();
    const onClickContinue = () => {
      // const data = {
      //   image: 'http://example.com',
      // };
      loading.value = true;
      services
        .signatureVerification()
        .then((response) => {
          loading.value = false;
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        })
        .catch(() => {
          loading.value = false;
        });
    };

    return {
      onClickContinue,
      signatureValue,
      clientName,
      verifySignature,
      loading,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/VerifySignature.scss';
</style>
